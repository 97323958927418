import { LayoutProps } from "./FMain";

const Authenticate: React.FC<LayoutProps> = ({ nextStep, sessionValues }) => {
  // To close the tab

  const handleClose = () => {
    window.open("about:blank", "_self");
    window.close();
  };
  // Handle Click Authenticate
  const handleClick = (e: any) => {
    e.preventDefault();
    nextStep();
    window.open(
      `${sessionValues.VerificationUri}`,
      "_blank",
      "toolbar=yes,scrollbars=yes,resizable=yes,bottom=1000,right=1000,width=700,height=600"
    );
  };

  return (
    <div className="container">
      <div
        className="row d-flex justify-content-center align-items-center text-center"
        style={{ height: "100vh" }}
      >
        <div className="authenticate" style={{ maxWidth: "700px" }}>
          <h1>Cool! Let's authenticate with Microsoft.</h1>
          <div style={{ textAlign: "justify" }}>
            <p>
              By clicking the button below, a new window will open where you'll
              have to enter a device code which is written below. You'll then be
              prompted to grant access to our Application so that we may fully
              onboard you into the service.
            </p>
            <p>
              Please be very sure of the credentials you'll use, as that's the
              tenant that will be used for the service.
            </p>
            <p>
              Note that the user you use <b>MUST</b> have{" "}
              <b>Global Administrator</b> rights assigned to it.
            </p>
            <p>
              If you would like to abort this, feel free to close the tab, or
              click the close button.
            </p>
          </div>

          <h1>Code: {sessionValues.UserCode}</h1>
          <button
            className="btn btn-outline-danger btn-lg m-3"
            onClick={handleClose}
          >
            Close
          </button>
          <button
            className="btn btn-outline-primary btn-lg m-3"
            onClick={(e) => {
              handleClick(e);
            }}
          >
            Authenticate with Microsoft
          </button>
        </div>
      </div>
    </div>
  );
};

export default Authenticate;
