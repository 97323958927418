import { LayoutProps } from "./FMain";
import Spinner from "react-bootstrap/Spinner";
import { useEffect, useState } from "react";

const Delegation: React.FC<LayoutProps> = ({
  nextStep,
  probingSessionValues,
  setStep,
}) => {
  // Handle Click Authenticate
  const [bbSpinner, setBBSpinner] = useState<boolean>(false);
  const [bbDoneFlag, setBBDoneFlag] = useState<boolean>(false);
  const [spSpinner, setSPSpinner] = useState<boolean>(false);
  const [spDoneFlag, setSPDoneFlag] = useState<boolean>(false);
  //------------------ Next Step function to be added -------------------//
  const handleBaseDelegation = (e: any) => {
    setBBSpinner(true);
    window.open(
      `${probingSessionValues.Delegations[0].Url}`,
      "_blank",
      "toolbar=yes,scrollbars=yes,resizable=yes,top=1000,left=1000,width=700,height=600"
    );
  };
  const handleSSPDelegation = (e: any) => {
    setSPSpinner(true);
    window.open(
      `${probingSessionValues.Delegations[1].Url}`,
      "_blank",
      "toolbar=yes,scrollbars=yes,resizable=yes,bottom=1000,right=1000,width=700,height=600"
    );
  };

  const checkDelegations = (probingSessionValues: any) => {
    if (probingSessionValues.Delegations[0].Status === "Complete") {
      setBBDoneFlag(true);
      setBBSpinner(false);

      if (!probingSessionValues.Delegations[1]) {
        setStep(7);
      }
    }
    if (probingSessionValues.Delegations[1]) {
      if (probingSessionValues.Delegations[1].Status === "Complete") {
        setSPDoneFlag(true);
        setSPSpinner(false);
      }
      if (
        probingSessionValues.Delegations[0].Status === "Complete" &&
        probingSessionValues.Delegations[1].Status === "Complete"
      ) {
        setStep(7);
      }
    }
  };
  useEffect(() => {
    checkDelegations(probingSessionValues);
  }, [probingSessionValues]);
  return (
    <div className="container">
      <div
        className="row d-flex justify-content-center align-items-center "
        style={{ height: "100vh" }}
      >
        <div className="delegation" style={{ maxWidth: "700px" }}>
          <h1 className="text-center">One More Thing...</h1>
          <div style={{}}>
            {probingSessionValues.Delegations[1] ? (
              <p>
                We have two applications that need your consent to leverage the
                correct APIs. Please give your consent now.
              </p>
            ) : (
              <p>
                We have an application that need your consent to leverage the
                correct APIs. Please give your consent now.
              </p>
            )}
            <div className="row">
              <div className="col-md-7">
                <h3>Base Backup Application</h3>
                <p>
                  This application is used to backup your files and connect to
                  your tenant.
                </p>
              </div>
              <div className="col-md-5">
                <h5>
                  Status :{" "}
                  {probingSessionValues.Delegations[0].Status ===
                  "NeedDelegation"
                    ? "Pending"
                    : probingSessionValues.Delegations[0].Status === "Complete"
                    ? "Complete"
                    : "Pending"}{" "}
                </h5>
                {bbSpinner ? (
                  <Spinner animation="border" variant="primary" />
                ) : bbDoneFlag ? (
                  <button
                    className="btn btn-outline-success btn-lg m-3"
                    disabled
                  >
                    Done
                  </button>
                ) : (
                  <button
                    className="btn btn-outline-primary btn-lg m-3"
                    onClick={(e) => handleBaseDelegation(e)}
                  >
                    Grant Rights
                  </button>
                )}
              </div>
            </div>
            {probingSessionValues.Delegations[1] ? (
              <div className="row">
                <div className="col-md-7">
                  <h3>Self-Service Portal Application</h3>
                  <p>
                    This application is used to authenticate the self-service
                    portal so that you may browse your backups.
                  </p>
                </div>
                <div className="col-md-5">
                  <h5>
                    Status :{" "}
                    {probingSessionValues.Delegations[1].Status ===
                    "NeedDelegation"
                      ? "Pending"
                      : probingSessionValues.Delegations[1].Status ===
                        "Complete"
                      ? "Complete"
                      : "Pending"}
                  </h5>
                  {spSpinner ? (
                    <Spinner animation="border" variant="primary" />
                  ) : spDoneFlag ? (
                    <button
                      className="btn btn-outline-success btn-lg m-3"
                      disabled
                    >
                      Done
                    </button>
                  ) : (
                    <button
                      className="btn btn-outline-primary btn-lg m-3"
                      onClick={(e) => handleSSPDelegation(e)}
                    >
                      Grant Rights
                    </button>
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Delegation;
