import { useEffect, useState } from "react";
import { LayoutProps } from "./FMain";
import ProgressBar from "react-bootstrap/ProgressBar";

const Checking: React.FC<LayoutProps> = () => {
  let [loaded, setLoaded] = useState<number>(5);
  const progressValue = () => {
    while (loaded !== 95) {
      setLoaded(loaded++);
    }
    return loaded;
  };

  useEffect(() => {
    setInterval(() => {
      setLoaded(progressValue());
    }, 30000);
  });

  return (
    <>
      <div className="container">
        <div
          className="row d-flex justify-content-center align-items-center text-center"
          style={{ height: "100vh" }}
        >
          <div className="checking" style={{ maxWidth: "700px" }}>
            <h1>We're Checking...</h1>
            <p>This may take a minute or two. Please do not close this tab.</p>
            <ProgressBar now={loaded} animated />
          </div>
        </div>
      </div>
    </>
  );
};

export default Checking;
