import { LayoutProps } from "./FMain";
const Confirmation: React.FC<LayoutProps> = () => {
  // To close the tab
  const handleClose = () => {
    window.open("about:blank", "_self");
    window.close();
  };

  return (
    <div className="container">
      <div
        className="row d-flex justify-content-center align-items-center text-center"
        style={{ height: "100vh" }}
      >
        <div className="confirmation" style={{ maxWidth: "700px" }}>
          <h1>You're all set!</h1>
          <div className="m-3" style={{ textAlign: "justify" }}>
            <h6>
              You're fully authenticated and provisioning for your organization
              has been queued. As we're seeing higher that usual demand at this
              moment, provisioning may take up to 48 hours.
            </h6>
            <h6>
              Once complete, you'll receive an email with all your details.
            </h6>
          </div>
          <h5>Thank you for your trust!</h5>
          <button
            className="btn btn-outline-danger btn-lg m-3"
            onClick={handleClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
