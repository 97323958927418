/* eslint-disable @typescript-eslint/no-unused-vars */
import { useForm } from "react-hook-form";
import { LayoutProps } from "./FMain";
import Spinner from "react-bootstrap/Spinner";
import { useState } from "react";

type FormValues = {
  BackupReportEmail: string;
  BackupReportConfirmEmail: string;
  DataLocation: string;
};

const Information: React.FC<LayoutProps> = ({
  handleChange,
  saveTenantData = (data: any) => {},
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormValues>();
  const [spinner, setSpinner] = useState<boolean>(false);

  // On Submit Data
  const submitData = (data: any) => {
    setSpinner(true);
    saveTenantData(data);
  };

  return (
    <form onSubmit={handleSubmit(submitData)}>
      <div className="container">
        <div
          className="row d-flex justify-content-center align-items-center text-center"
          style={{ height: "100vh" }}
        >
          <div className="information" style={{ maxWidth: "700px" }}>
            <h1>We'll need some information:</h1>
            <div className="m-3">
              <h6 className="form-label">
                Email where we should send backup report emails:
              </h6>
              <input
                type="text"
                id="email"
                className="form-control form-control-sm"
                placeholder="example@contoso.com"
                {...register("BackupReportEmail", {
                  required: "Email is required!",
                })}
                onChange={handleChange("BackupReportEmail")}
              />
              {errors.BackupReportEmail && (
                <p className="text-danger" style={{ fontSize: 14 }}>
                  {"Email is Required!"}
                </p>
              )}
            </div>
            <div className="m-3">
              <h6 className="form-label">
                Please, Confirm your email address!:
              </h6>
              <input
                autoComplete="false"
                type="text"
                id="confirmEmail"
                className="form-control form-control-sm"
                placeholder="example@contoso.com"
                {...register("BackupReportConfirmEmail", {
                  required: "Email is required!",
                  validate: (value) =>
                    value === watch("BackupReportEmail") ||
                    "Emails don't match.",
                })}
              />
              {errors.BackupReportConfirmEmail && (
                <p className="text-danger" style={{ fontSize: 14 }}>
                  {"Emails don't Match!"}
                </p>
              )}
            </div>
            <div className="m-3">
              <h6 className="form-label">Please choose your data location:</h6>
              <select
                className="form-select form-select-sm"
                {...register("DataLocation", {
                  required: "Location is required!",
                })}
                onChange={handleChange("DataLocation")}
                name="DataLocation"
              >
                <option value="">Select</option>
                <option value="CA">Canada</option>
                <option value="US">US</option>
              </select>
              {errors.DataLocation && (
                <p className="text-danger" style={{ fontSize: 14 }}>
                  {"Location is Requried!"}
                </p>
              )}
            </div>
            {spinner ? (
              <Spinner animation="border" variant="primary" />
            ) : (
              <button className="btn btn-primary btn-lg text-center m-3">
                Next
              </button>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default Information;
