import { LayoutProps } from "./FMain";

const Validation: React.FC<LayoutProps> = ({ nextStep, values }) => {
  // To redirect
  const redirect = () => {
    nextStep();
  };

  // To close the tab
  const handleClose = () => {
    window.open("about:blank", "_self");
    window.close();
  };
  return (
    <div className="container">
      <div
        className="row d-flex justify-content-center align-items-center text-center"
        style={{ height: "100vh" }}
      >
        <div className="validation" style={{ maxWidth: "700px" }}>
          <h1>Here's the information we have:</h1>
          <h6>
            Your organization is called:{" "}
            {values.RegistrationInfo.OrganizationName}
          </h6>
          <h6>Your Name is: {values.RegistrationInfo.ContactName}</h6>
          <h6>Your Email is: {values.RegistrationInfo.ContactEmail}</h6>
          <p>Does that looks right to you?</p>
          <button
            className="btn btn-danger btn-lg text-center m-3"
            onClick={handleClose}
          >
            No, this is wrong!
          </button>
          <button
            className="btn btn-primary btn-lg text-center m-3"
            onClick={redirect}
          >
            Yes, I Make it happen!
          </button>
        </div>
      </div>
    </div>
  );
};

export default Validation;
