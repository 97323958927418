import { LayoutProps } from "./FMain";
import Spinner from "react-bootstrap/Spinner";
import { useState } from "react";

const ExpiredOnboarding: React.FC<LayoutProps> = ({
  setStep,
  values,
  startSessionFromExpire = () => {},
  expireRedirection,
  sessionValues,
  expireSessionRedirection = () => {},
}) => {
  // To close the tab
  const handleClose = () => {
    window.open("about:blank", "_self");
    window.close();
  };
  const [spinner, setSpinner] = useState<boolean>(false);
  // Handle Click Authenticate
  const handleClick = (e: any) => {
    e.preventDefault();
    setSpinner(true);
    expireRedirection === "NeedDelegation"
      ? expireSessionRedirection()
      : expireRedirection === "Authenticate"
      ? expireSessionRedirection()
      : expireRedirection === "Expired"
      ? expireSessionRedirection()
      : expireSessionRedirection();
  };
  return (
    <div className="container">
      <div
        className="row d-flex justify-content-center align-items-center text-center"
        style={{ height: "100vh" }}
      >
        <div className="expireOboarding" style={{ maxWidth: "500px" }}>
          <h1>Let's pick back up where we left off.</h1>
          <div style={{ textAlign: "justify" }}>
            {expireRedirection === "Expired" ? (
              <p>
                It looks like you quit during the last onboarding session, would
                you like to start a new one?
              </p>
            ) : (
              <p>
                It looks like you quit during the last onboarding session, would
                you like to continue with the onboarding process?
              </p>
            )}
          </div>
          <div className="expireOnboarding2 m-2">
            <h5>Here's the information we have:</h5>
            <h6>
              Your organization is called:{" "}
              {values.RegistrationInfo.OrganizationName}
            </h6>
            <h6>Your Name is: {values.RegistrationInfo.ContactName}</h6>
            <h6>Your Email is: {values.RegistrationInfo.ContactEmail}</h6>
            <h6>
              Your Backup Report Email is:{" "}
              {values.RegistrationInfo.BackupReportEmail}
            </h6>
            <h6>
              Your Data Location is:{" "}
              {values.RegistrationInfo.DataLocation === "CA" ? "Canada" : "USA"}
            </h6>
            <p>Does that looks right to you?</p>
          </div>
          <div>
            {spinner ? (
              <Spinner animation="border" variant="primary" />
            ) : (
              <>
                <button
                  className="btn btn-outline-danger btn-lg m-3"
                  onClick={handleClose}
                >
                  Close
                </button>
                <button
                  className="btn btn-outline-primary btn-lg m-3"
                  onClick={(e) => handleClick(e)}
                >
                  Yes ! I Make it happen.
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpiredOnboarding;
