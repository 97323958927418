import { useEffect, useState } from "react";
import { LayoutProps } from "./FMain";
// CSS
import ProgressBar from "react-bootstrap/ProgressBar";

const Loading: React.FC<LayoutProps> = ({ nextStep, loadInfo }) => {
  let [loaded, setLoaded] = useState<number>(5);

  const redirect = (e: any) => {
    // e.preventDefault();
    nextStep();
  };

  const progressValue = () => {
    while (loaded !== 101) {
      setLoaded(loaded++);
    }
    return loaded;
  };

  useEffect(() => {
    setInterval(() => {
      setLoaded(progressValue());
    }, 1000);
  });

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     redirect("true");
  //   }, 3000);

  //   return () => clearInterval(timer);
  // }, [loadInfo === true]);

  return (
    <>
      <div className="container">
        <div
          className="row d-flex justify-content-center align-items-center text-center"
          style={{ height: "100vh" }}
        >
          <div className="loading" style={{ maxWidth: "700px" }}>
            <h1>Welcome !</h1>
            <p>Please hold on while we're fetching your information.</p>
            <ProgressBar now={loaded} animated />
          </div>
        </div>
      </div>
    </>
  );
};

export default Loading;
