import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// CSS
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

// Components
import Loading from "./Loading";
import Confirmation from "./Confirmation";
import Information from "./Information";
import Validation from "./Validation";
import Authenticate from "./Authenticate";
import Checking from "./Checking";
import Delegation from "./Delegation";
import AuthChecking from "./AuthChecking";
import ExpiredOnboarding from "./ExpiredOnboarding";
import { UserNotFound, SomethingWentWrong } from "./404";
export interface LayoutProps {
  nextStep?: any;
  handleChange?: any;
  baseURL?: any;
  values?: any;
  saveTenantData?: (data: any) => void;
  sessionValues?: any;
  probingSessionValues?: any;
  messageCode?: string;
  startSession?: any;
  setStep?: any;
  startSessionFromExpire?: () => void;
  expireSessionRedirection?: () => void;
  expireRedirection?: any;
  loadInfo?: boolean;
}

export default function FMain() {
  // state for steps
  const [step, setStep] = useState<number>(1);

  const [formData, setFormData] = useState({
    UniqueID: "",
    OrganizationName: "",
    OrganizationUniqueName: "",
    ContactName: "",
    ContactEmail: "",
    DataLocation: "",
    BackupReportEmail: "",
  });
  const [sessionData, setSessionData] = useState([]);
  const [probingSessionData, setProbingSessionData] = useState([]);
  const [startProbingSession, setStartProbbingSession] = useState(false);
  const [expireRedirection, setExpireRedirection] = useState("");
  const [loadInfo, setLoadInfo] = useState<boolean>(false);

  // function for going to next step by increasing step state by 1
  const nextStep = useCallback(() => {
    setStep(step + 1);
  }, [step]);
  // jump to the page
  const callStep = (step: any) => {
    setTimeout(() => {
      setStep(step);
    }, 5000);
  };
  // handling form input data by taking onchange value and updating our previous form data state
  const handleChange = (input: any) => (e: any) => {
    // input value from the form
    const { value } = e.target;

    //updating for data state taking previous state and then adding new value to create new object
    setFormData((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };

  //   Steps to fetchData
  const params = useParams();
 const baseURL = `https://${process.env.REACT_APP_API_URL}/api/onboarding/${params.uid}`;

  // Fetching Tenant Data
  const getTenantAPI = async () => {
    try {
      await axios.get(baseURL).then((res) => {
        setLoadInfo(true);
        setFormData(res.data);
        // If user not found
        if (res.data.Status === "Error") {
          callStep(10);
          return false;
        }
        if (
          !res.data.RegistrationInfo.BackupReportEmail &&
          !res.data.RegistrationInfo.DataLocation
        ) {
          // Check if the registration data already contains Backup report email and Data Location
          callStep(2);
          // If the registration data, backup report email and data location is present, then check for the registration session
        } else {
          // if there are no registration session, then skip, the data entry and start new session
          if (res.data.RegistrationSessions.length === 0) {
            startSession();
            callStep(4);
          } else {
            // Checking all sessions
            res.data.RegistrationSessions.slice(0)
              .reverse()
              .every((regSession: any) => {
                // Check if the session contains, any "Complete" status, then display done page
                if (regSession.Status === "Complete") {
                  callStep(9);
                  return false;
                }
                // if the status is "Need Delegation", then redirect to need delegation with the probbing session, do not start new session
                else if (regSession.Status === "NeedDelegation") {
                  callStep(8);
                  setSessionData(regSession);
                  setExpireRedirection("NeedDelegation");

                  return false;
                }
                // If the status is "Pending", then redirect to authentication page
                else if (regSession.Status === "Pending") {
                  setExpireRedirection("Authenticate");
                  setSessionData(regSession);
                  callStep(8);
                  return false;
                }
                // If all the statuses are "Expired", then start new session, with whole new process
                else if (regSession.Status === "Expired") {
                  setExpireRedirection("Expired");
                  callStep(8);
                  return false;
                }
                // If every thing goes wrong; then start new session
                else {
                  callStep(8);
                  return false;
                }
              });
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getTenantAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveTenantData = useCallback(
    async (data: any) => {
      try {
        await axios
          .put(baseURL, {
            UniqueID: params.uid,
            RegistrationInfo: {
              BackupReportEmail: data.BackupReportEmail,
              DataLocation: data.DataLocation,
            },
          })
          .then((res) => {
            if (res.status === 200) {
              setFormData(res.data);
              startSession();
            } else {
              console.log("Something went wrong!!");
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [baseURL, params.uid, formData.BackupReportEmail, formData.DataLocation]
  );
  // Starting tenant onboarding session
  const startSession = async () => {
    try {
      await axios.post(baseURL + `/sessions`).then((res) => {
        if (res.status === 200) {
          setSessionData(res.data);
          setStartProbbingSession(true);
          probingSession(res.data);
          nextStep();
        } else {
          console.log("Something went Wrong!!");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const expireSessionRedirection = () => {
    if (expireRedirection === "NeedDelegation") {
      setStartProbbingSession(true);
      probingSession(sessionData);
    } else if (expireRedirection === "Authenticate") {
      setStartProbbingSession(true);
      probingSession(sessionData);
    } else if (expireRedirection === "Expired") {
      startSessionFromExpire();
    } else {
      alert("Something went wrong!");
    }
  };

  // Start tenant session from expire
  const startSessionFromExpire = async () => {
    try {
      await axios.post(baseURL + `/sessions`).then((res) => {
        if (res.status === 200) {
          setSessionData(res.data);
          probingSession(res.data);
          setStartProbbingSession(true);
          setStep(4);
        } else {
          console.log("Something went Wrong!!");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  // Starting probbing session
  const probingSession = useCallback(
    async (sessionData: any) => {
      try {
        await axios.get(baseURL + `/sessions/${sessionData.id}`).then((res) => {
          if (res.status === 200) {
            setProbingSessionData({ ...res.data });

            if (
              localStorage.getItem("callPendingSession") === "true" &&
              res.data.Status === "Pending"
            ) {
              setSessionData(res.data);
              setStep(4);
              localStorage.setItem("callPendingSession", "false");
            }
            if (
              localStorage.getItem("callDelegation") === "true" &&
              res.data.Status === "NeedDelegation"
            ) {
              setStep(6);
              localStorage.setItem("callDelegation", "false");
            }
            if (
              localStorage.getItem("callConfirm") === "true" &&
              res.data.Status === "Complete"
            ) {
              setStartProbbingSession(false);
              setStep(9);
              localStorage.setItem("callConfirm", "false");
            }
          } else {
            console.log("Something went Wrong!!");
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    [baseURL]
  );
  useEffect(() => {
    if (startProbingSession === true) {
      setInterval(() => {
        probingSession(sessionData);
      }, 30000);
    }
  }, [probingSession, sessionData, startProbingSession]);

  switch (step) {
    case 1:
      return <Loading nextStep={nextStep} loadInfo={loadInfo} />;
    case 2:
      return <Validation nextStep={nextStep} values={formData} />;
    case 3:
      return (
        <Information
          handleChange={handleChange}
          saveTenantData={saveTenantData}
        />
      );
    case 4:
      return <Authenticate nextStep={nextStep} sessionValues={sessionData} />;
    case 5:
      return <AuthChecking probingSessionValues={probingSessionData} />;
    case 6:
      return (
        <Delegation
          nextStep={nextStep}
          probingSessionValues={probingSessionData}
          setStep={setStep}
        />
      );
    case 7:
      return <Checking probingSessionValues={probingSessionData} />;
    case 8:
      return (
        <ExpiredOnboarding
          setStep={setStep}
          values={formData}
          startSessionFromExpire={startSessionFromExpire}
          sessionValues={sessionData}
          expireSessionRedirection={expireSessionRedirection}
          expireRedirection={expireRedirection}
        />
      );
    case 9:
      return <Confirmation />;
    case 10:
      return <UserNotFound />;
    default:
      return <SomethingWentWrong />;
  }
}
