import { BrowserRouter, Routes, Route } from "react-router-dom";
// CSS
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
// Components
import FMain from "./Components/FMain";
import { PageNotFound } from "./Components/404";
function App() {
  localStorage.setItem("callDelegation", "true");
  localStorage.setItem("callConfirm", "true");
  localStorage.setItem("callPendingSession", "true");
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/:uid">
            <Route index element={<FMain />} />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
