import { LayoutProps } from "./FMain";
const UserNotFound: React.FC<LayoutProps> = () => {
  // To close the tab
  const handleClose = () => {
    window.open("about:blank", "_self");
    window.close();
  };
  return (
    <div className="container">
      <div
        className="row d-flex justify-content-center align-items-center text-center"
        style={{ height: "100vh" }}
      >
        <div className="UserNotFound" style={{ maxWidth: "700px" }}>
          <h1>Oh no, resource not found!</h1>
          <div className="m-3" style={{ textAlign: "justify" }}>
            <h6>
              Sorry, we didn't find a registration with the ID you supplied. The exact cause of the problem is unknown. If you require
              any assistance, you can return or attempt contacting our <a href="https://helpdesk.sherweb.com/en/support/home">help
              center.</a>
            </h6>
          </div>
          <h5>See you soon!</h5>
          <button
            className="btn btn-outline-danger btn-lg m-3"
            onClick={handleClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};
const PageNotFound: React.FC<LayoutProps> = () => {
  // To close the tab
  const handleClose = () => {
    window.open("about:blank", "_self");
    window.close();
  };
  return (
    <div className="container">
      <div
        className="row d-flex justify-content-center align-items-center text-center"
        style={{ height: "100vh" }}
      >
        <div className="UserNotFound" style={{ maxWidth: "700px" }}>
          <h1>Oh no, Page not found!</h1>
          <div className="m-3" style={{ textAlign: "justify" }}>
            <h6>
              Sorry, the page you've requested doesn't exist. If you need
              any assistance please try contacting our <a href="https://helpdesk.sherweb.com/en/support/home">help
              center.</a>
            </h6>
          </div>
          <h5>See you soon!</h5>
          <button
            className="btn btn-outline-danger btn-lg m-3"
            onClick={handleClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

const SomethingWentWrong: React.FC<LayoutProps> = () => {
  // To close the tab
  const handleClose = () => {
    window.open("about:blank", "_self");
    window.close();
  };
  return (
    <div className="container">
      <div
        className="row d-flex justify-content-center align-items-center text-center"
        style={{ height: "100vh" }}
      >
        <div className="UserNotFound" style={{ maxWidth: "700px" }}>
          <h1>Oh no, Something went Wrong!</h1>
          <div className="m-3" style={{ textAlign: "justify" }}>
            <h6>
              Sorry, something went wrong. Your request was not able to be served at
              this time, and we are very sorry for that. Please check back again
              or try contacting our <a href="https://helpdesk.sherweb.com/en/support/home">help
              center</a> for any assistance.
            </h6>
          </div>
          <h5>See you soon!</h5>
          <button
            className="btn btn-outline-danger btn-lg m-3"
            onClick={handleClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export { UserNotFound, PageNotFound, SomethingWentWrong };
